<template>
  <div class="mt-2">
    <div class="inside">
      <div class="myform">
        <div class="title-spaced ">
          <div class="subtitle space-adjustment">
            プライバシーポリシー
          </div>
          <div class="">
            株式会社コレクト・エージェンシーは、人材紹介事業を営む企業の責任として、応募者様の個人情報を保護するために細心の注意を払っています。 具体的には、以下の方針に基づき、個人情報の管理・運用を行なっておりますので、下記の内容について同意をいた だいた上で個人情報をご提供いただきますようお願い致します。
          </div>
          <div class="subtitle">
            1.個人情報とは
          </div>
          <div class="">
            個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述、または個人別の各種番号、記号その他の符号、画像または音声によって当該個人を識別できるもの(当該情報のみでは識別できないが、他の情報と容易 に照合することができ、それにより当該個人を識別できるものを含む)。
          </div>
          <div class="subtitle">
            2.個人情報の利用について
          </div>
          <div class="">
            (1) 個人情報は、以下に定める目的の範囲内で取得し、利用いたします。 お預かりした個人情報を本人の許可なく、求人企業や第三者に開示することはありません。
          </div>
          <div class="">
            A) 応募者が求人企業や職業紹介会社に対して応募、登録、問い合わせ等を行なった場合の、当該求人企業または職業紹介会社に対する情報提供(応募者が転職支援サービスに登録している個人情報が提供されます)
          </div>
          <div class="">
            B) 求人情報の提供、応募先企業における書類選考、応募に基づく求人企業及び提携会社等への情報提供等、適切な職業紹介サービスを行なう為。
          </div>
          <div class="">
            C) 応募・入退社等の事実に関する確認(対象企業、関連する機関に対する確認も含みます)
          </div>
          <div class="">
            D) 転職支援サービスの向上及びシステム、サービスの開発及び向上
          </div>
          <div class="">
            E) アフターサービス、お問い合わせ内容に対する返答
          </div>
          <div class="">
            F) 採用業務及び職業紹介業務に関するコンサルティング
          </div>
          <div class="">
            G) 転職サービス、その他弊社が提供する各種サービスに関する情報、統計内容の提供
          </div>
          <div class="">
            H) アンケートの実施
          </div>

          <div class="subtitle">
            3.個人情報提供の任意性
          </div>
          <div class="">
            個人情報の提供は、本人の任意によるものとします。但し、必要となる情報が不足している場合、当サイト及び弊社の転職支援、サービスを提供することができない場合があります。
          </div>
          <div class="subtitle">
            4.個人情報の第三者への開示
          </div>
          <div class="">
            弊社は、本人の同意を得ずに個人情報を第三者に開示することは、原則いたしません。 開示を行なうのは、提供先・提供情報内容を特定したうえで、本人の同意を得た場合に限ります。 但し以下の場合は、関係法令に反しない範囲で、本人の同意なく個人情報を開示することがあります。
          </div>
          <div class="">
            A) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
          </div>
          <div class="">
            B) 公衆衛生の向上または児童の健全な育成のために特に必要がある場合であって、本人の承諾を得ることが困難で ある場合
          </div>
          <div class="">
            C) 国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合
          </div>
          <div class="">
            D) 裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、個人情報につ いての開示を求められた場合
          </div>
          <div class="">
            E) 法令により開示または提供が許容されている場合
          </div>

          <div class="subtitle">
            5.個人情報の管理
          </div>
          <div class="">
            弊社は、個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩などの危険に対して、技術面及び組織面において必要な安全対策を継続的におこなっております。
          </div>

          <div class="subtitle">
            6.個人情報の訂正・削除
          </div>
          <div class="">
            登録した個人情報の訂正・削除はマイページにログインして自ら行なっていただくか、または担当宛にご連絡ください。
          </div>

          <div class="subtitle">
            7.書類の返却
          </div>
          <div class="">
            弊社が提供するサービスをご利用いただくにあたってお預かりした書類一式は、本人及び第三者にいかなる場合も返却致しません。また、個人情報を保管する必要がなくなったと弊社が判断した場合は、本人の同意を得ることなく廃 棄する場合があります。
          </div>

          <div class="subtitle">
            8.個人情報管理責任者
          </div>
          <div class="">
            個人情報は、下記担当者が責任をもって管理するものとします。
          </div>

          <div class="">
            〒141-0031
          </div>
          <div class="">
            東京都 品川区 ⻄五反田 1-28-3 ハイラーク五反田 411
          </div>
          <div class="">
            株式会社コレクト・エージェンシー 関東事業部⻑(個人情報保護管理者)
          </div>

          <div class="subtitle">
            9.プライバシーポリシーの変更
          </div>
          <div class="">
            本プライバシーポリシーは法令の改定、時事状況により見直し、予告することなく変更することがあります。
          </div>

          <div class="subtitle">
            10.お問い合わせ先
          </div>
          <div class="">
            個人情報に関するお問い合わせは、サイト管理窓口にご連絡ください。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   BRow, BCol, BButton,
// } from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
    // BButton,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      //
    },
  },
}
</script>

<style scoped>
.whole {
    /* background-color: rgba(217, 229, 255, 0.3); */
    color:#555555;
    margin: 5% 0;
  }
  .inside {
    margin: 2%;
    padding: 0 0 0% 0;
    /* background-color: white; */
    height: 100%;
  }
  @media screen and (min-width:896px){
      .inside {
        margin: 1%;
      }
    }
.myform{
  /* background-color: white; */
  width: 70%;
  margin: 3% auto;
  line-height: 2.8rem;
}
@media screen and (max-width:480px){
/*画面幅が480pxまでの時*/
  .myform{
    width: 90%;
    font-size: 0.6rem;
    line-height: 1.4rem;
  }
}
/* @media screen and (min-width:781px){ */
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .myform{
    width: 50%;
  }
}
@media screen and (max-width:480px){
/*画面幅が480pxまでの時*/
  .subtitle {
    font-weight: 600;
    margin: 15% 0 3% 0;
    font-size: 1rem;
  }
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .subtitle {
    font-weight: 600;
    margin: 3% 0;
    font-size: 1.4rem;
  }
}
.space-adjustment {
  margin: 25% 0 0% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space-adjustment {
    margin: 10% 0 0% 0;
  }
}
</style>
