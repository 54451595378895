<template>
  <b-row class="match-height">
    <Privacy />
  </b-row>
</template>

<script>
import {
  BRow,
  // BCol,
} from 'bootstrap-vue'
import Privacy from '@/components/basic/privacy/Privacy.vue'

export default {
  components: {
    BRow,
    Privacy,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
</style>
